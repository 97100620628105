export const labelsDashFinanc = {
  month31: [
    "SI",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
  ],
  month30: [
    "SI",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30
  ],
  month28: [
    "SI",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28
  ],
  month29: [
    "SI",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29
  ],
  month1Q: ["SI", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  month2Q31: [
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
  ],
  month2Q30: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
  month2Q29: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
  yearly: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ],
  yearly1S: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun"],
  yearly2S: ["Jul", "Ago", "Set", "Out", "Nov", "Dez"]
};

export const monthsGlobal = [
  { full: "Janeiro", abv: "Jan", number: "01" },
  { full: "Fevereiro", abv: "Fev", number: "02" },
  { full: "Março", abv: "Mar", number: "03" },
  { full: "Abril", abv: "Abr", number: "04" },
  { full: "Maio", abv: "Mai", number: "05" },
  { full: "Junho", abv: "Jun", number: "06" },
  { full: "Julho", abv: "Jul", number: "07" },
  { full: "Agosto", abv: "Ago", number: "08" },
  { full: "Setembro", abv: "Set", number: "09" },
  { full: "Outubro", abv: "Out", number: 10 },
  { full: "Novembro", abv: "Nov", number: 11 },
  { full: "Dezembro", abv: "Dez", number: 12 }
];
